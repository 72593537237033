import React from "react";
import { Watch } from "react-loader-spinner";
import { usePromiseTracker } from "react-promise-tracker";

/**
 * OverlayAndSpinner is a component that renders an overlay with a spinner animation
 * whenever a promise is in progress. It is used to indicate loading states.
 *
 * @component
 * @returns {React.Element} - A spinner overlay component.
 */
export default function OverlayAndSpinner() {
  // Hook to track whether any promise is currently in progress.
  const { promiseInProgress } = usePromiseTracker();

  return (
    <>
      {promiseInProgress && (
        <div className="overlay">
          <div className="loader-spinner-container">
            <Watch color="#00BFFF" height={60} width={60} />
          </div>
        </div>
      )}
    </>
  );
}
