import axiosInstance from "../axios";

class ReportingService {
	getCurrentExchangeRate = () => axiosInstance.get(`/reporting/exchange_rates`);

	addNewIncomeStory = (date, payout, currency, percent, payouts_list) => {
		const data = new URLSearchParams();
		data.append("date", date);
		data.append("total_amount", payout);
		data.append("currency", currency);
		data.append("total_percent", percent);
		data.append("lists", JSON.stringify(payouts_list));

		return axiosInstance.post(`/reporting/incomes`, data);
	};
	
	addNewExpenseStory (date, name, amount, currency, exchange_rate) {
		const data = new URLSearchParams();
		data.append("date", date);
		data.append("name", name);
		data.append("amount", amount);
		data.append("currency", currency);
		// data.append("exchange_rate", exchange_rate)

		return axiosInstance.post(`/reporting/expenses`, data);
	};

	addNewPayOutStory = (date, comment, amount, currency, percent, exchange_rate) => {
		const data = new URLSearchParams();
		data.append("date", date);
		data.append("comment", comment);
		data.append("amount", amount);
		data.append("currency", currency);
		data.append("percent", percent);
		data.append("exchange_rate", exchange_rate);
		return axiosInstance.post(`/reporting/payouts`, data);
	};

	refreshExpensesAmount = (start_date, end_date, exchange_rate) => {
		const data = new URLSearchParams();
		data.append("start_date", start_date);
		data.append("end_date", end_date);
		data.append("exchange_rate", exchange_rate);
		return axiosInstance.post(`/reporting/expenses/refresh-amount`, data);
	
	}

	sendWeeklyReport = (dateFrom, dateTo) => axiosInstance.get(`/reporting/send-report?start_date=${dateFrom}&end_date=${dateTo}`);
	getSelaries = (dateFrom, dateTo) =>
		axiosInstance.get(`/reporting/salaries?start_date=${dateFrom}&end_date=${dateTo}`);
	getIncomesStories = (dateFrom, dateTo) =>
		axiosInstance.get(`/reporting/incomes?start_date=${dateFrom}&end_date=${dateTo}`);
	getExpensesStories = (dateFrom, dateTo) =>
		axiosInstance.get(`/reporting/expenses?start_date=${dateFrom}&end_date=${dateTo}`);
	getWithdrawalHistory = (dateFrom, dateTo) =>
		axiosInstance.get(`/reporting/payouts?start_date=${dateFrom}&end_date=${dateTo}`);
	getBalances = () =>	axiosInstance.get('/reporting/get-balances');


	deletePaymentById = (paymentId) => axiosInstance.delete(`/reporting/payouts/${paymentId}`);
	deleteIncomeById = (incomeId) => axiosInstance.delete(`/reporting/incomes/${incomeId}`);
	deleteExpenceById = (expenseId) => axiosInstance.delete(`/reporting/expenses/${expenseId}`);
}

export default ReportingService;
