import React from "react";

export const AppContext = React.createContext({
  token: "",
  setTokenValue: () => {},
  user: {},
  setUser: () => {},
  statisticsDate: [],
  setStatisticsDate: () => {},
});
