import axiosInstance from "../axios";

class UserService {
  login(username, password) {
    const data = new URLSearchParams();
    data.append("email", username);
    data.append("password", password);

    return axiosInstance.post("/auth/login", data);
  }
  logout = () => axiosInstance.post("/auth/logout", {});

  getUserInformation = async () => await axiosInstance.post("/auth/me", {});

  getOfficeInformation = () => axiosInstance.get("reporting/manager-login", {});

  changePassword(oldPassword, newPassword) {
    const data = new URLSearchParams();
    data.append("current_password", oldPassword);
    data.append("new_password", newPassword);

    return axiosInstance.post("/account/change-password", data);
  }
}

export default UserService;
