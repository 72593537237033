export const errorMessages = {
  timeOut: `Ошибка подключения`,
  wrongLoginData: `Неправильный пароль или e-mail!`,
  emptyField: `Заполните все поля!`,
  differentPasswords: `Поле "Новый пароль" и "Повторите пороль" должны быть одиноковы.`,
  serverError: `Внутренняя ошибка сервера`,
  userIsAlreadyLoggedIn:
    "В данный момент этот аккаунт используется для работы." +
    " Чтобы войти в аккаунт обратитесь к администратору",
};

export const ROLES = {
  ADMIN: "admin",
  MANAGER: "manager",
  REPORTING: "reporting",
};

export const SCREEN_SIZES = {
  mobile_v: 360,
  mobile_h: 576,
  tablet_v: 992,
  tablet_h: 1200,
  desktop: 1400,
};

export const defaultStatusColors = [
  "#0DCAF0",
  "#20C997",
  "#FFC107",
  "#FD7E14",
  "#D63384",
  "#6F42C1",
  "#0D6EFD",
  "#6610F2",
  "#F8B045",
  "#AE934C",
  "#512476",
  "#AF7151",
  "#E9996E",
  "#80B152",
  "#C276BA",
  "#FF6C89",
  "#6090E5",
  "#FF4E06",
  "#7FB2B6",
  "#428B4F",
];

export const recallAndTransferColors = ["#198754", "#DC3545"];

export const BEGINNING_OF_THE_ENG_ALPHABET_IN_ASCII = 65;

export const PER_PAGE = [10, 25, 50];

export const DB_FIELDS = [
  {
    displayText: "Имя",
    value: "first_name",
  },
  {
    displayText: "Отчество",
    value: "surname",
  },
  {
    displayText: "Фамилия",
    value: "last_name",
  },
  {
    displayText: "ФИО",
    value: "fullname",
  },
  {
    displayText: "Телефон",
    value: "phone",
  },
  {
    displayText: "@Телеграм",
    value: "telegram_link",
  },
  {
    displayText: "Область",
    value: "region",
  },
  {
    displayText: "Город",
    value: "city",
  },
  {
    displayText: "Адрес",
    value: "address",
  },
  {
    displayText: "Возраст",
    value: "age",
  },
  {
    displayText: "Часовой пояс",
    value: "timezone",
  },
  {
    displayText: "Доп. поле",
    value: "additional_field1",
  },
  {
    displayText: "Доп. дата",
    value: "additional_date",
  },
  {
    displayText: "ИНН",
    value: "tin",
  },
  {
    displayText: "СНИЛС",
    value: "snils",
  },
  {
    displayText: "Паспорт",
    value: "passport",
  },
];
const addBreaklines = (children) =>
  children.map((child, i) => [child, <br key={i} />]);

export const REDRAFT_REDERERS = {
  /**
   * Those callbacks will be called recursively to render a nested structure
   */
  inline: {
    // The key passed here is just an index based on rendering order inside a block
    BOLD: (children, { key }) => <strong key={key}>{children}</strong>,
    ITALIC: (children, { key }) => <em key={key}>{children}</em>,
    UNDERLINE: (children, { key }) => <u key={key}>{children}</u>,
    CODE: (children, { key }) => <span key={key}>{children}</span>,
  },
  /**
   * Blocks receive children and depth
   * Note that children are an array of blocks with same styling,
   */
  blocks: {
    unstyled: (children) => children.map((child, i) => <p key={i}>{child}</p>),
    blockquote: (children) => (
      <blockquote>{addBreaklines(children)}</blockquote>
    ),
    "header-one": (children) =>
      children.map((child, i) => <h1 key={i}>{child}</h1>),
    "header-two": (children) =>
      children.map((child, i) => <h2 key={i}>{child}</h2>),
    // You can also access the original keys of the blocks
    "code-block": (children, { keys }) => (
      <pre key={keys[0]}>{addBreaklines(children)}</pre>
    ),
    // or depth for nested lists
    "unordered-list-item": (children, { depth, keys }) => (
      <ul key={keys[keys.length - 1]} className={`ul-level-${depth}`}>
        {children.map((child, i) => (
          <li key={i}>{child}</li>
        ))}
      </ul>
    ),
    "ordered-list-item": (children, { depth, keys }) => (
      <ol key={keys.join("|")} className={`ol-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>{child}</li>
        ))}
      </ol>
    ),
    // If your blocks use meta data it can also be accessed like keys
  },
  /**
   * Entities receive children and the entity data
   */
  entities: {
    // key is the entity key value from raw
    LINK: (children, data, { key }) => (
      <a key={key} href={data.url}>
        {children}
      </a>
    ),
  },
  /**
   * Array of decorators,
   * Entities receive children and the entity data,
   * inspired by https://facebook.github.io/draft-js/docs/advanced-topics-decorators.html
   * it's also possible to pass a custom Decorator class that matches the [DraftDecoratorType](https://github.com/facebook/draft-js/blob/master/src/model/decorators/DraftDecoratorType.js)
   */
  decorators: [
    {
      // by default linkStrategy receives a ContentBlock stub (more info under Creating the ContentBlock)
      // strategy only receives first two arguments, contentState is yet not provided
      strategy: () => {},
      // component - a callback as with other renderers
      // decoratedText a plain string matched by the strategy
      // if your decorator depends on draft-js contentState you need to provide convertFromRaw in redraft options
      component: ({ children, decoratedText }) => (
        <a href={decoratedText}>{children}</a>
      ),
    },
  ],
};

export const FULL_EDITOR_TOOLBAR = {
  options: ["inline", "blockType", "fontSize", "textAlign"],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline", "strikethrough"],
  },
  fontSize: {
    icon: undefined,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  blockType: {
    inDropdown: true,
    options: [
      "Normal",
      "H1",
      "H2",
      "H3",
      "H4",
      "H5",
      "H6",
      "Blockquote",
      "Code",
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontFamily: {
    options: [
      "Arial",
      "Georgia",
      "Impact",
      "Tahoma",
      "Times New Roman",
      "Verdana",
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["left", "center", "right", "justify"],
  },
};

export const MAIN_EDITOR_TOOLBAR = {
  options: ["inline", "fontSize", "textAlign"],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "superscript",
      "subscript",
    ],
  },
  fontSize: {
    icon: undefined,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["left", "center", "right", "justify"],
  },
};

export const ADDITIONAL_EDITOR_TOOLBAR = {
  options: [],
};

export const timezones = [
  { name: "+2 UTC", value: 2 },
  { name: "+3 UTC", value: 3 },
  { name: "+4 UTC", value: 4 },
  { name: "+5 UTC", value: 5 },
  { name: "+6 UTC", value: 6 },
  { name: "+7 UTC", value: 7 },
  { name: "+8 UTC", value: 8 },
  { name: "+9 UTC", value: 9 },
  { name: "+10 UTC", value: 10 },
  { name: "+11 UTC", value: 11 },
  { name: "+12 UTC", value: 12 },
];
export const NAME_OF_MONTHS = {
  0: "января",
  1: "февраля",
  2: "марта",
  3: "апреля",
  4: "мая",
  5: "июня",
  6: "июля",
  7: "августа",
  8: "сентября",
  9: "октября",
  10: "ноября",
  11: "декабря",
};

export const TINY_EDITOR_TOOLTIP =
  "undo redo | formatselect | " +
  "bold italic backcolor link | alignleft aligncenter " +
  "alignright alignjustify | bullist numlist outdent indent customIndent | " +
  "removeformat";

export const TINY_EDITOR_FONT_SIZE_FORMATS =
  "6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt";

export const TINY_EDITOR_FONTS =
  "Arial=arial; Arial Black=arial black; Book Antique=book antiqua; Comic Sans MS=comic sans ms;" +
  "Courier New=courier new; Georgia=georgia; Helvetica=helvetica; Tahoma=tahoma;" +
  "Times New Roman=times new roman; Trebuchet MS=trebuchet ms; Verdana=verdana";

export const TINY_EDITOR_LINE_HEIGHTS = "1 1.1 1.2 1.3 1.4 1.5 2 2.2";

export const REPORTING_DAYS_TYPES = {
  weekdays: "weekdays",
  weekends: "weekends",
};

/**
 * @note Must match the date on the backend side. The format of the date is "YYYY-MM-DD".
 * @type {string}
 * @constants {string} ANOTHER_CONSTANT - Інша константа.
 */
export const SEPARATING_REPORTING_STARTING_DATA = "2023-11-13";

export const adminHeaderNavigation = [
  {
    linkTo: "/statistics/managers",
    navName: "Статистика",
    iconName: "pie_chart",
    iconClass: "material-icons",
  },
  {
    linkTo: "/managers",
    navName: "Менеджеры",
    iconName: "people",
    iconClass: "material-icons",
  },
  {
    linkTo: "/scripts",
    navName: "Скрипты",
    iconName: "code",
    iconClass: "material-icons",
  },
];

export function getManagerHeaderNavigation(user) {
  const navList = !!user?.visible_statistics
    ? [
        {
          linkTo: `/statistics/managers/${user.id}`,
          navName: "История",
          iconName: "history",
          iconClass: "material-icons",
        },
        {
          linkTo: "/my-tests",
          navName: "Тесты",
          iconName: "library_add_check",
          iconClass: "material-icons",
        },
        {
          linkTo: "/my-scripts",
          navName: "Скрипты",
          iconName: "code",
          iconClass: "material-icons",
        },
      ]
    : [
        {
          linkTo: "/my-tests",
          navName: "Тесты",
          iconName: "library_add_check",
          iconClass: "material-icons",
        },
        {
          linkTo: "/my-scripts",
          navName: "Скрипты",
          iconName: "code",
          iconClass: "material-icons",
        },
      ];

  return navList;
}

export const adminManeNavigation = [
  {
    linkTo: "/",
    navName: "Главная",
    iconName: "home",
    iconClass: "material-icons",
    titleNav: true,
  },
  {
    navName: "Базы",
    iconName: "storage",
    iconClass: "material-icons",
    children: [
      {
        linkTo: "/my-databases",
        navName: "Мои базы",
        iconName: "contact_page",
        iconClass: "material-icons",
      },
      {
        linkTo: "/import-databases",
        navName: "Импорт базы",
        iconName: "note_add",
        iconClass: "material-icons",
      },
      {
        linkTo: "/find-duplicates",
        navName: "Поиск дублей",
        iconName: "find_in_page",
        iconClass: "material-icons",
      },
    ],
  },
  {
    navName: "Настройки",
    iconName: "settings",
    iconClass: "material-icons",
    children: [
      {
        linkTo: "/preinstall",
        navName: "Предустановка",
        iconName: "manage_accounts",
        iconClass: "material-icons",
      },
      {
        linkTo: "/managers",
        navName: "Менеджеры",
        iconName: "people",
        iconClass: "material-icons",
      },
      {
        linkTo: "/scripts",
        navName: "Скрипты",
        iconName: "code",
        iconClass: "material-icons",
      },
      {
        linkTo: "/chat-settings",
        navName: "Jivo chat",
        iconName: "chat",
        iconClass: "material-icons",
      },
    ],
  },
  {
    navName: "Безопасность",
    iconName: "lock",
    iconClass: "material-icons",
    children: [
      {
        linkTo: "/profile",
        navName: "Сменить пароль",
        iconName: "password",
        iconClass: "material-icons",
      },
      {
        linkTo: "/access-control",
        navName: "Привязка IP",
        iconName: "security",
        iconClass: "material-icons",
      },
    ],
  },
  {
    linkTo: "/tests",
    navName: "Тесты",
    iconName: "library_add_check",
    iconClass: "material-icons",
    titleNav: true,
  },
  {
    navName: "Статистика",
    iconName: "pie_chart",
    iconClass: "material-icons",
    children: [
      {
        linkTo: "/statistics/bases",
        navName: "Базы",
        iconName: "storage",
        iconClass: "material-icons",
      },
      {
        linkTo: "/statistics/managers",
        navName: "Менеджеры",
        iconName: "people",
        iconClass: "material-icons",
      },
      {
        linkTo: "/statistics/transferred_clients",
        navName: "Переданные",
        iconName: "compare_arrows",
        iconClass: "material-icons",
      },
      {
        linkTo: "/statistics/dashboard",
        navName: "Дашборд",
        iconName: "assessment",
        iconClass: "material-icons",
      },
    ],
  },
  {
    linkTo: "/documents",
    navName: "Документы",
    iconName: "description",
    iconClass: "material-icons",
    titleNav: true,
    isNewTable: true,
  },
];
